const enTranslation = {
  Breadcrumb_Cycles: 'Cycles',
  Breadcrumb_Actions: 'Actions',
  Breadcrumb_Audits: 'Audits',
  Breadcrumb_Home: 'Home',
  Breadcrumb_NAListSearch: 'NA List Search',
  Breadcrumb_Role: 'Role',
  Breadcrumb_Enrollments: 'Enrollments',
  Breadcrumb_List: 'List',
  Breadcrumb_OrganizationSearch: 'Organizations',
  Breadcrumb_Products: 'Products',
  Breadcrumb_Program: 'Programs',
  Breadcrumb_Region: 'Regions',
  Breadcrumb_Countries: 'Countries',
  Breadcrumb_SubjectMatterExpertise: 'Subject Matter Expertise',
  Breadcrumb_Contacts: 'Contacts',
  Breadcrumb_SentEmails: 'Sent Emails',
  Breadcrumb_UserManagement: 'User Management',
  Breadcrumb_Announcements: 'Announcements',
  Breadcrumb_MyAnnouncements: 'My Announcements',
  Breadcrumb_Groups: 'Groups',
  Breadcrumb_AccreditationBodies: 'Accreditation Bodies',
  Breadcrumb_Methods: 'Methods',
  Breadcrumb_EditSubjectMatterExpertise: 'Edit Subject Matter Expertise',
  Breadcrumb_Locations: 'Locations',
  Breadcrumb_Units: 'Units',
  Breadcrumb_IgnoreList: 'Ignore List',
  Breadcrumb_Roles: 'Roles',
  Breadcrumb_Users: 'Users',
  Breadcrumb_Asset: 'Asset',
  Breadcrumb_Announccements: 'Announcements',
  Breadcrumb_MyAnnounccements: 'My Announcements',
  Breadcrumb_MethodEquivalence: 'Method Equivalence',
  Breadcrumb_EditCycle: 'Edit Cycle',
  Breadcrumb_LabSummaryReports: 'Location Reports',
  Breadcrumb_CompanySummaryReports: 'Company Summary Reports',
  Breadcrumb_Assets: 'Assets',

  Btn_Save: 'Save',
  Btn_AddLocation: 'Add Location',
  Btn_Approve: 'Approve',
  Btn_ExportTo: 'Export To',
  Btn_Export: 'Export',
  Btn_Export_All: 'Export All',
  Btn_Import: 'Import',
  Btn_SaveFilters: 'Save Filter',
  Btn_Message: 'Message',
  Btn_SaveFilter: 'Save Filter',
  Btn_SavedFilter: 'Saved Filter',
  Btn_ShowFilter: 'Show Filter',
  Btn_HideFilter: 'Hide Filter',
  Btn_Delete: 'Delete',
  Btn_Apply: 'Apply',
  Btn_ClearFilters: 'Clear Filters',
  Btn_ManageDisplay: 'Manage Display',
  Btn_Logout: 'Log Out',
  Btn_Add: 'Add',
  Btn_Cancel: 'Cancel',
  Btn_Yes: 'Yes',
  Btn_No: 'No',
  Btn_Upload: 'Upload',
  Btn_Submit: 'Submit',
  Btn_Update: 'Update',
  Btn_AddNew: 'Add New',
  Btn_Close: 'Close',
  Btn_EditNa: 'Edit NA List',
  Btn_NewAction: 'New Action',
  Btn_GenerateReport: 'Generate Report',
  Btn_ShowMoreComments: 'More',
  Btn_Load5MoreLastCompletedActions: 'Load 5 more last completed actions',
  Btn_EditEnrollment: 'Edit Enrollment List',
  Btn_Audit: 'Audit',
  Btn_UploadThenProcess: 'Upload Then Process',
  Btn_ProcessExisting: 'Process Existing',
  Btn_AddMethods: 'Add Method(s)',
  Btn_CopyMethods: 'Copy Method(s)',
  Btn_ManageFilters: 'Manage Filters',
  Btn_Recheck: 'Recheck',
  Btn_Caveats: 'Caveats',
  Btn_Copy: 'Copy',
  Btn_Profile: 'Profile',
  Btn_Validate: 'Validate',
  Btn_Enrollment: 'Enrollment',
  Btn_ChangeMyPassword: 'Change My Password',
  Btn_NAList: 'NA List',
  Btn_Contact: 'Contact',
  Btn_PrintPDF: 'Print PDF',
  Btn_DownloadExcel: 'Download Excel',
  Btn_FinalizeAndEmail: 'Finalize and Email',
  Btn_Finalize: 'Finalize',
  Btn_Refinalize: 'Refinalize',
  Btn_Broadcast: 'Broadcast',
  Btn_SaveAsTemplate: 'Save as Template',
  Btn_Reset: 'Reset',
  Btn_AddActions: 'Add Action',
  Btn_Replicate: 'Replicate',
  Btn_Retry: 'Retry',
  Btn_Edit: 'Edit',
  Btn_UpdateMD: 'Update MD',
  Btn_Recalculate: 'Recalculate',
  Btn_Legend: 'Legend',
  Btn_SaveAs: 'Save As',
  Btn_Start: 'Start',
  Btn_Complete: 'Complete',
  Btn_ReOpen: 'ReOpen',
  Btn_Comment: 'Comment',
  Btn_History: 'History',
  Btn_More: 'More',
  Btn_Less: 'Less',

  Column_Action: 'Action',
  Column_Title: 'Title',
  Column_Filename: 'Filename',
  Column_GeneratedOn: 'Generated On',
  Column_Date: 'Date',
  Column_Headline: 'Headline',
  Column_ProgramName: 'Program',
  Column_CreatedBy: 'Created By',
  Column_CreatedByName: 'Created By',
  Column_CreatedOn: 'Created On',
  Column_ProgramProducts: 'Products',
  Column_ListName: 'List Name',
  Column_Program: 'Program',
  Column_Location: 'Location',
  Column_StaticLabId: 'Static Lab ID',
  Column_MethodName: 'Method Name',
  Column_OrganizationName: 'Organization Name',
  Column_ProductType: 'Product Type',
  Column_ProductName: 'Product Name',
  Column_LocationID: 'ID',
  Column_LocationName: 'Location',
  Column_LocationAbbreviation: 'Abbreviation',
  Column_LocationType: 'Type',
  Column_LocationAlias: 'Alias',
  Column_Country: 'Country',
  Column_Zone: 'Zone',
  Column_Active: 'Active',
  Column_LastUpdatedOn: 'Updated On',
  Column_LastUpdatedBy: 'Updated By',
  Column_LastUpdatedByName: 'Updated By',
  Column_CountryName: 'Country Name',
  Column_CountryCode: 'Country Code',
  Column_ProgramID: 'Program ID',
  Column_RegionCode: 'Region Code',
  Column_RegionName: 'Region Name',
  Column_RoleName: 'Role Name',
  Column_Description: 'Description',
  Column_Select: 'Select', //
  Column_FirstName: 'First Name',
  Column_LastName: 'Last Name',
  Column_Email: 'Email',
  Column_UserID: 'User ID',
  Column_AccreditationBody: 'Accreditation Body',
  Column_ExpirationDate: 'Expiration Date',
  Column_LastAuditDate: 'Last Audit Date',
  Column_AuditDate: 'Audit Date',
  Column_MajorNC: 'Major NC',
  Column_MinorNC: 'Minor NC',
  Column_NoofMajorNC: 'No of Major NC',
  Column_NoofMinorNC: 'No of Minor NC',
  Column_CertificateLink: 'Certificate Link',
  Column_ScopeLink: 'Scope Link',
  Column_NoOfRecommendations: 'No of Recommendations',
  Column_FileName: 'File Name',
  Column_UploadedOn: 'Uploaded On',
  Column_UploadedBy: 'Uploaded By',
  Column_Result: 'Result',
  Column_Method: 'Method',
  Column_ZoneListEntryName: 'Zone',
  Column_LabName: 'Lab Name',
  Column_SampleName: 'Sample Name',
  Column_PostedDate: 'Posted Date',
  Column_RecalculationRequired: 'Calculation Required',
  Column_TestMethod: 'Test Method',
  Column_Results: '# Results',
  Column_ZCARs: '# Z CARs',
  Column_PercentOfZCars: '% of Z CARs',
  Column_CompletedCARs: '# Completed CARs',
  Column_PendingCARs: '# Pending CARs',
  Column_PendingReview: '# Pending Approval',
  Column_PendingSubmission: '# Pending Submission',
  Column_RejectedCARs: '# Rejected CARs',
  Column_Rejected: '# Rejected',
  Column_GroupName: 'Group Name',
  Column_Z: 'Z',
  Column_F: 'F',
  Column_J: 'J',
  Column_CJ: 'CJ',
  Column_EN: 'EN',
  Column_Parameter: 'Parameter',
  Column_Unit: 'Unit',
  Column_Mean: 'Mean',
  Column_StdDev: 'Std Dev',
  Column_ZScore: 'Z-Score',
  Column_FCAR: 'F CAR',
  Column_Reason: 'Reason',
  Column_CAR: 'CAR',
  Column_AbsoluteDeviation: 'Abs Dev',
  Column_AnnouncementNumber: 'Announcement Number',
  Column_BroadcastedBy: 'Broadcasted By',
  Column_ReadReceipt: 'Read Receipt',
  Column_CompletedOn: 'Completed On',
  Column_OldValue: 'Old Value',
  Column_NewValue: 'New Value',
  Column_UpdatedBy: 'Updated By',
  Column_UpdatedOn: 'Updated On',
  Column_BusinessLines: 'Business Lines',
  Column_AssetName: 'Asset Name',
  Column_AssetNumber: 'Asset Number',
  Column_SerialNumber: 'Serial Number',
  Column_PhysicalLocation: 'Physical Location',
  Column_RetailCurrency: 'Retail Currency',
  Column_AssetType: 'Asset Type',
  Column_AssetSubType: 'Asset SubType',
  Column_ApplicableNoofMethods: 'Applicable No of Methods',
  Column_Make: 'Make',
  Column_Model: 'Model',
  Column_Status: 'Status',
  Column_SourceSystem: 'Source System',
  Column_BusinessLine: 'Business Line',
  Column_Acknowledged: 'Acknowledged',

  Dialog_Title_AddSamples: 'Add Samples',
  Dialog_Title_AddTrialsToSample: 'Add Trials to Sample',
  Dialog_Title_ReleaseNotes: 'Release Notes',

  Error_MaxLengthIs100: 'Max Length is 100',
  Error_Message_SomethingWentWrong: 'Something went wrong!',
  Error_LocationNameIsRequired: 'Location Name is required',
  Error_LocationTypeIsRequired: 'Location Type is required',
  Error_CountryIsRequired: 'Country is required',
  Error_RegionIsRequired: 'Region is required',
  Error_RegionCodeIsRequired: 'Region Code is Required',
  Error_MaxLengthIs5: 'Maximum Length is 5',
  Error_ProgramNameRequired: 'Program Name is Required',
  Error_ProgramProductsRequired: 'Program Products Required',
  Error_OrganizationRequired: 'Organization is Required',
  Error_BusinessLineRequired: 'Business Line is Required',
  Error_LoadingDataError: 'Loading Data Error',
  Error_NoItemsFound: 'No items found!',
  Error_DueDateRequired: 'Due Date is required',
  Error_ProcessDateRequired: 'Process Date is required',
  Error_LocationIsRequired: 'Location is Required',
  Error_CycleNameIsRequired: 'Cycle Name Is Required',
  Error_ProgramIsRequired: 'Program is Required',
  Error_MethodNameIsRequired: 'Method Name Is Required',
  Error_LocationAbbreviationIsRequired: 'Location Abbreviation is required',
  Error_MaxLengthIs3: 'Max Length is 3',
  Error_MaxLength: 'Max Length is {{length}}',
  Error_OnlyUpperCaseLettersAllowed: 'Only UpperCase Letters Allowed',
  Error_NameIsRequired: 'Name is required',
  Error_NoPresetFound: 'No preset found!',
  Error_Message_ChangePasswordError: 'Change Password Failed',
  Error_FirstNameIsRequired: 'First Name is Required',
  Error_LastNameIsRequired: 'Last Name is Required',
  Error_EmailIsRequired: 'Email is Required',
  Error_MaxLengthIs50: 'Maximum Length is 50',
  Error_RoleIsRequired: 'Role is required',
  Error_DescriptionRequired: 'Description is required',
  Error_TypeIsRequired: 'Type is required',
  Error_BodyIsRequired: 'Body is required',
  Error_AccreditationBodyRequired: 'Accreditation Body is required',
  Error_ExpirationDateRequired: 'Expiration date is required',
  Error_UploadDocumentFirst: 'Upload the document first',
  Error_ReasonIsRequired: 'Reason is required',
  Error_TitleIsRequired: 'Title is required',
  Error_TestItemAlreadyExists: 'Test item(s) [{{items}}] already exists in the program',
  Error_NAReasonIsRequired: 'NA Reason is required if NA is selected',
  Error_ValidResultsMustBeInteger: 'Valid Results must be an integer',
  Error_ItemMustBeDecimal: '{{item}} must be decimal',
  Error_ExplanationRequired: 'Explanation is Required',
  Error_DueDateBeforeReportDate: 'Due Date must be before Report Date',
  Error_ShipDateBeforeDueDate: 'Ship Date must be before Due Date',
  Error_ShipDateBeforeReportDate: 'Ship Date must be before Report Date',
  Error_DateFormatIsRequired: 'Date format is Required',
  Error_DateTimeFormatIsRequired: 'DateTime format is Required',
  Error_ActionTypeRequired: 'Action Type is required',
  Error_AssignedToRequired: 'Assigned To is required',
  Error_TitleRequired: 'Title is required',
  Error_UnableToSaveAsset: 'Unable To Save Asset',

  Header_Attachments: 'Attachments',
  Header_Actions: 'Actions',
  Header_General: 'General',
  Header_ProgramInfo: 'Program Info',
  Header_MyActions: 'My Actions',
  Header_AddLocation: 'Add Location',
  Header_EditLocation: 'Edit Location',
  Header_RegionSearch: 'Regions',
  Header_EditCycle: 'Edit Cycle',
  Header_ViewCycle: 'View Cycle',
  Header_NAListSearch: 'NA Lists',
  Header_AddRole: 'Add Role',
  Header_EditRole: 'Edit Role',
  Header_EnrollmentSearch: 'Enrollments',
  Header_List: 'List',
  Header_Action_Column_New: 'To do',
  Header_Action_Column_Started: 'In Progress',
  Header_Action_Column_Completed: 'Completed',
  Header_OrganizationSearch: 'Organizations',
  Header_Products: 'Products',
  Header_ProgramSearch: 'Programs',
  Header_AddProgram: 'Add Program',
  Header_EditProgram: 'Edit Program',
  Header_AddMethod: 'Add Method',
  Header_EditMethod: 'Edit Method',
  Header_NewEnrollment: 'New Enrollment',
  Header_Date: 'Date',
  Header_Time: 'Time',
  Header_EditNAList: 'Edit NA List',
  Header_EditCycleNAList: 'Edit NA List for Cycle {{cycleName}}',
  Header_Region: 'Region',
  Header_SampleAttachments: 'Sample Attachments',
  Header_Sample: 'Sample',
  Header_EditSubjectMatterExpertise: 'Edit Subject Matter Expertise',
  Header_Announcements: 'Announcements',
  Header_MyAnnouncements: 'My Announcements',
  Header_MyAnnouncementInfo: 'My Announcement Info',
  Header_AccreditationBodies: 'Accreditation Bodies',
  Header_ResultReview: 'Result Review',
  Header_CycleInformation: 'Cycle Information',
  Header_GeneralInfo: 'General Info',
  Header_CycleInfoDynamic: 'Cycle Info - {{cycleName}}',
  Header_Cycle: 'Cycle: {{cycleName}}',
  Header_Cycles: 'Cycles',
  Header_AuditForCycle: 'Audit for - {{cycleName}}',
  Header_AddEditAccreditation: '{{op}} Accreditations - {{location}}',
  Header_NAInfo_ProgramName: 'Program: {{ProgramName}}',
  Header_NAInfo_LocationName: 'Location: {{LocationName}}',
  Header_NAInfo_ProgramLocationName: 'Program: {{ProgramName}} - Location: {{LocationName}}',
  Header_SampleInfo: 'Sample Info',
  Header_Role: 'Roles',
  Header_User: 'Users',
  Header_AddUser: 'Add User',
  Header_EditUser: 'Edit User',
  Header_Info: 'Info',
  Header_RoleAndAccess: 'Role And Access',
  Header_CreateUser: 'Create User',
  Header_Groups: 'Groups',
  Header_AnnouncementInfo: 'Announcement Info',
  Header_ServiceInfo: 'Service Information',
  Header_CalibrationInfo: 'Calibration Information',
  Header_AddAnnouncement: 'Add Announcement',
  Header_MyAddAnnouncement: 'Add My Announcement',
  Header_EditAnnouncement: 'Edit Announcement',
  Header_MyEditAnnouncement: 'Edit My Announcement',
  Header_Message: 'Message',
  Header_AddContact: 'Add Contact',
  Header_EditContact: 'Edit Contact',
  Header_AddAsset: 'Add Asset',
  Header_EditAsset: 'Edit Asset',
  Header_AddGroup: 'Add Group',
  Header_EditGroup: 'Edit Group',
  Header_CycleSearch: 'Cycles',
  Header_AuditSearch: 'Audit',
  Header_ActionSearch: 'Actions',
  Header_AddCycle: 'Add Cycle',
  Header_Methods: 'Methods',
  Header_Locations: 'Locations',
  Header_Countries: 'Countries',
  Header_Units: 'Units',
  Header_IgnoreList: 'Ignore Lists',
  Header_Profile: 'Profile',
  Header_SubjectMatterExpertise: 'Subject Matter Expertise',
  Header_Contacts: 'Contacts',
  Header_SentEmails: 'Sent Emails',
  Header_AddAccreditationBodies: 'Add Accreditation Body',
  Header_EditAccreditationBodies: 'Edit Accreditation Body',
  Header_ReviewResults: 'Review Results',
  Header_Accreditations: 'Accreditations',
  Header_CycleInfo: 'Cycle Info - {{name}}',
  Header_Results: 'Results',
  Header_CARPreview: 'CAR Preview',
  Header_CARPreviewAndFinalizeSample: 'CAR Preview and Finalize Sample',
  Header_Refinalize: 'Refinalize',
  Header_WelcomeToDashboard: 'Welcome to CPMS home page.',
  Header_MethodEquivalence: 'Method Equivalence',
  Header_EditMethodEquivalence: 'Edit Method Equivalence',
  Header_Reports: 'Reports',
  Header_LocationReports: 'Location Reports',
  Header_CompanySummaryReport: 'Company Summary Reports',
  Header_ZCARPercentageReports: 'Z CAR % Reports',
  Header_ZCARPercentageReport: 'Z CAR % Report',
  Header_ReportGrading: 'Report Grading',
  Header_AutoImportData: 'Auto Import Data',
  Header_LocationReport_HistoryInfo: 'Location Report on {{generatedOn}} by {{generatedBy}}',
  Header_CompanySummaryReport_HistoryInfo: 'Company Summary Report on {{generatedOn}} by {{generatedBy}}',
  Header_Assets: 'Assets',

  Label_Acknowledged: 'Acknowledged',
  Label_Edit: 'Edit',
  Label_CycleName: 'Cycle',
  Label_PTP: 'Organization',
  Label_Accreditation: 'Accreditation',
  Label_AccreditationBody: 'Accreditation Body',
  Label_BelongsTo: 'Belongs To',
  Label_Organization: 'Organization',
  Label_ProgramName: 'Program',
  Label_ProgramProducts: 'Products',
  Label_CycleStatus: 'Cycle Status',
  Label_CreatedFrom: 'Created From',
  Label_CreatedTo: 'Created To',
  Label_ShipDate: 'Ship Date',
  Label_CarsDue: 'CARs Due',
  Label_DueDate: 'Due Date',
  Label_ReportedDate: 'Reported Date',
  Label_YRQTR: 'YR-QTR',
  Label_Remarks: 'Remarks',
  Label_ProgramComments: 'Program Comments',
  Label_ProcessDate: 'Process Date',
  Label_Name: 'Name',
  Label_Samples: 'Samples',
  Label_Due: 'Due',
  Label_Created: 'Created',
  Label_RoleName: 'Role Name',
  Label_Active: 'Active',
  Label_Title: 'Title',
  Label_Trending: 'Trending',
  Label_Custom: 'Custom',
  Label_ChartTypes: 'Chart Types',
  Label_Zone: 'Zone',
  Label_Roles: 'Roles',
  Label_Description: 'Description',
  Label_Summary: 'Summary',
  Label_User: 'User',
  Label_LocationName: 'Location Name',
  Label_LocationType: 'Location Type',
  Label_LocationAlias: 'Location Alias',
  Label_Alias: 'Alias',
  Label_Region: 'Region',
  Label_RegionName: 'Region Name',
  Label_Regions: 'Regions',
  Label_CountryName: 'Country Name',
  Label_Tenant: 'Tenant',
  Label_State: 'State',
  Label_City: 'City',
  Label_PostCode: 'Post Code',
  Label_AddressLine2: 'Address Line 2',
  Label_AddressLine1: 'Address Line 1',
  Label_From: 'From',
  Label_To: 'To',
  Label_DateFrom: 'From',
  Label_DateTo: 'To',
  Label_Frequency: 'Frequency',
  Label_LocationAbbreviation: 'Location Abbreviation',
  Label_Abbreviation: 'Abbreviation',
  Label_Timezone: 'Timezone',
  Label_UserName: 'Username',
  Label_FirstName: 'First Name',
  Label_LastName: 'Last Name',
  Label_LastUpdatedOn: 'Last Updated On',
  Label_LastUpdatedBy: 'Last Updated By',
  Label_By: 'By',
  Label_NAList: 'NA List',
  Label_ListName: 'List Name',
  Label_Lab: 'Lab',
  Label_PTPOrganization: 'Organization',
  Label_SampleName: 'Sample Name',
  Label_NumberOfTrials: 'No of Trials',
  Label_Filters: 'Filters',
  Label_Method: 'Method',
  Label_TestItem: 'Parameter',
  Label_AutoImport: 'Auto Import',
  Label_FreqYr: 'Freq/Yr',
  Label_SearchMethod: 'Search Method',
  Label_No_Records_Found: 'No records found!',
  Label_ReportDate: 'Report Date',
  Label_MISC: 'MISC',
  Label_SupperssCARCreation: 'Supperss CAR Creation',
  Label_SupperssAutoEmails: 'Supperss Auto Emails',
  Label_Attachments: 'Attachments',
  Label_Parameter: 'Parameter',
  Label_TestMethod: 'Method',
  Label_Unit: 'Unit',
  Label_NAReason: 'NA Reason',
  Label_Explanation: 'Explanation',
  Label_Products: 'Products',
  Label_Historic: 'Historic',
  Label_Mode: 'Mode',
  Label_Single: 'Single',
  Label_HashSamples: '# Samples',
  Label_MethodInfo: 'Method Information',
  Label_Designation: 'Designation',
  Label_ParentMethod: 'Parent Method',
  Label_PriceSensitive: 'Price Sensitive',
  Label_TestItems: 'Parameters',
  Label_LocationInfo: 'Location Information',
  Label_General: 'General',
  Label_Address: 'Address',
  Label_BusinessLines: 'Business Lines',
  Label_Product: 'Product',
  Label_UnitName: 'Unit Name',
  Label_UnitType: 'Unit Type',
  Label_Removed: 'Removed',
  Label_Email: 'Email',
  Label_DateFormat: 'Date Format',
  Label_DateTimeFormat: 'Date Time Format',
  Label_Default: 'Default',
  Label_IgnoreList: 'Ignore List',
  Label_Category: 'Category',
  Label_Type: 'Type',
  Label_Role: 'Role',
  Label_NewPassword: 'New Password',
  Label_ConfirmNewPassword: 'Confirm New Password',
  Label_Phone: 'Phone',
  Label_Countries: 'Countries',
  Label_Locations: 'Locations',
  Label_ContactInformation: 'Contact Information',
  Label_AssetReceivedDate: 'Asset Received Date',
  Label_DateCommissioned: 'Date Commissioned',
  Label_AssetInformation: 'Asset Information',
  Label_AttachmentType: 'Attachment Type',
  Label_SmeInformation: 'Subject Matter Expertise information',
  Label_Template: 'Template',
  Label_FreeReferenceNumber: 'Free reference number',
  Label_DateValidUntil: 'Date Valid Until',
  Label_ContactPerson: 'Contact Person',
  Label_Recipients: 'Recipients',
  Label_Recipient: 'Recipient',
  Label_Subject: 'Subject',
  Label_EmailType: 'Email Type',
  Label_EmailStatus: 'Email Status',
  Label_SME: 'SME',
  Label_LaboratoryContact: 'Laboratory Contact',
  Label_Team: 'Team',
  Label_MailToOther: 'Mail to other',
  Label_Body: 'Body',
  Label_Signature: 'Signature',
  Label_ExtraInfoActionsToTake: 'Extra info / actions to take',
  Label_SendCopyToMyself: 'Send copy to myself',
  Label_RecipientsInBCC: 'Recipients in BCC',
  Label_ReadReceiptConfirmationRequired: 'Read receipt confirmation required',
  Label_GroupInfo: 'Group Information',
  Label_Memebers: 'Members',
  Label_Status: 'Status',
  Label_NewEnrollmentInfo: 'New Enrollment Information',
  Label_Element: 'Element',
  Label_Elements: 'Elements',
  Label_NND: 'NND',
  Label_Resolution: 'Resolution',
  Label_TestItemsForMethod: 'Parameters for {{ methodName }}',
  Label_ProductType: 'Product Group',
  Label_AnnouncementNumber: 'Announcement Number',
  Label_Validity: 'Validity',
  Label_Scope: 'Scope',
  Label_BroadcastedBy: 'Broadcasted By',
  Label_Freereferencenumber: 'Free reference number',
  Label_Readreceipt: 'Read receipt',
  Label_Read: 'Read',
  Label_ExportData: 'Export Data',
  Label_Closed: 'Closed',
  Label_ListEntry: 'List Entry',
  Label_NA: 'NA',
  Label_OrganizationName: 'Organization Name',
  Label_OrganizationType: 'Organization Type',
  Label_Location: 'Location',
  Label_Country: 'Country',
  Label_BusinessLine: 'Business Line',
  Label_ProductName: 'Product Name',
  Label_Sponsor: 'Sponsor',
  Label_Program: 'Program',
  Label_Cycle: 'Cycle',
  Label_Sample: 'Sample',
  Label_Comments: 'Comments',
  Label_ActionType: 'Action Type',
  Label_ActionStatus: 'Action Status',
  Label_CreatedOnFrom: 'Created From',
  Label_CreatedOnTo: 'Created To',
  Label_DueDateTo: 'Due Date To',
  Label_DueDateFrom: 'Due Date From',
  Label_ActionTitle: 'Action Title',
  Label_ImportFile: 'Import File',
  Label_ImportExistingFile: 'Import Existing File',
  Label_AppliedMethodsAndTest: 'Applied Method & Parameters',
  Label_FirstCheckboxFileUpload: 'Ignore Enrolled Locations that did not participate in this sample',
  Label_SecondCheckboxFileUpload: 'Ignore locations that are not enrolled but found in import file (Need to enrole Location)',
  Label_ThirdCheckboxFileUpload: 'Ignore extra MPU\'s that are setup in Program but not found in Import file',
  Label_FourthtCheckboxFileUpload: 'Ignore Missing MPUs (Found in Import File) that was not added to ignore List',
  Label_FifthCheckboxFileUpload: 'Ignore Result found in Import file (Marked as NA in CPMS)',
  Label_RowsPerPage: 'Rows Per Page',
  Label_ResultEntry: 'Result Entry',
  Label_CARInfo: 'CAR Info',
  Label_Result: 'Result',
  Label_Results: 'Results',
  Label_Report: 'Report',
  Label_LateReport: 'Late Report',
  Label_Stats: 'Stats',
  Label_StatsEntry: 'Stats Entry',
  Label_MethodName: 'Method Name',
  Label_ISOCountryCode: 'ISO Country Code',
  Label_CountryZone: 'Country Zone',
  Label_SubjectMatterExpertise: 'Subject Matter Expertise',
  Label_Subregion: 'Sub Region',
  Label_ContactType: 'Contact Type',
  Label_AssetType: 'Asset Type',
  Label_AssetSubType: 'Asset Sub Type',
  Label_AssetName: 'Asset Name',
  Label_Asset17025No: 'Asset / 17025 No.',
  Label_SerialNumber: 'Serial Number',
  Label_PhysicalLocation: 'Physical Location',
  Label_RetailValue: 'Retail Value',
  Label_RetailValueCurrency: 'Retail Value Currency',
  Label_ApplicableNoOfMethods: 'Applicable No. Of Methods',
  Label_Make: 'Make',
  Label_Model: 'Model',
  Label_ServiceScheduled: 'Service Scheduled',
  Label_ServiceStatus: 'Service Status',
  Label_NextServiceDate: 'Next Service Date',
  Label_CalibrationType: 'Calibration Type',
  Label_CalibrationSchedule: 'Calibration Schedule',
  Label_CalibrationProvider: 'Calibration Provider',
  Label_CalibrationContactMail: 'Calibration Contact Mail',
  Label_CalibrationStatus: 'Calibration Status',
  Label_CalibrationDate: 'Calibration Date',
  Label_NextCalibrationDate: 'Next Calibration Date',
  Label_Faults: 'Faults',
  Label_Action: 'Action',
  Label_Close: 'Close',
  Label_Attachment: 'Attachment',
  Label_Selected: 'Selected',
  Label_Methods: 'Methods',
  Label_EditList: 'Edit List',
  Label_ListEntries: 'List Entries',
  Label_Function: 'Function',
  Label_ViewMore: 'View More',
  Label_ViewLess: 'View Less',
  Label_AccreditationBodyInfo: 'Accreditation Body Info',
  Label_UpdateNAList: 'Update NA List',
  Label_AnnouncementNr: 'Announcement nr',
  Label_AnnouncementBy: 'Announcement by',
  Label_Broadcast: 'Broadcast',
  Label_ReadAndUnderstood: 'Read and understood',
  Label_Broadcasted: 'BROADCASTED',
  Label_CARDueDate: 'CAR Due Date',
  Label_Score: 'Score',
  Label_HideEmptyResults: 'Hide Empty Results',
  Label_HideEmptyColumn: 'Hide Empty Column',
  Label_AccreditationLogoText: 'Accreditation Logo Text',
  Label_CertificateHyperlink: 'Certificate Hyperlink',
  Label_ScopeHyperlink: 'Scope Hyperlink',
  Label_FTE: 'FTE',
  Label_Latitude: 'Latitude',
  Label_Longitude: 'Longitude',
  Label_MainContact: 'Main Contact',
  Label_EnterEmail: 'Enter Email',
  Label_SavingProgramMethods: 'Saving Program Methods',
  Label_Reason: 'Reason',
  Label_LinkedUser: 'Linked User',
  Label_Before: 'Before',
  Label_After: 'After',
  Label_Current: 'Current',
  Label_HiUser: 'Hi {{username}}!',
  Label_AssignedXActions: 'There are <0>{{n}}</0> new actions assigned to you.',
  Label_XAnnouncemnets: 'There are <0>{{n}}</0> new announcemnets broadcasted to you.',
  Label_IsTemplate: 'Is Template',
  Label_AssignedTo: 'Assigned to',
  Label_ConfirmAction: 'Confirm {{actionName}}',
  Label_DialogConfirmationMessage: 'Are you sure that you want to {{actionName}} selected samples?',
  Label_CycleCancelConfirmation: 'Are you sure you want to cancel the cycle?',
  Label_AssetInactiveConfirmation: 'Are you sure you want to inactivate the Asset?',
  Label_ProgramEditConfirmation: 'Are you sure you want to apply the changes?',
  Label_MethodInfoUpdateConfirmation: 'Do you wish to update the Method Information?',
  Label_MethodParamaterUpdateConfirmation: 'Do you wish to save changes in Method Parameters?',
  Label_MethodInfoUpdateTitle: 'Update Method Information',
  Label_MethodParameterUpdateTitle: 'Save Method Parameters',
  Label_EquivalenceInfo: 'Equivalence Information',
  Label_EditSampleEnrollment: 'Edit Sample Enrollment',
  Label_Search: 'Search',
  Label_AllLocation: 'All Location',
  Label_AllPrograms: 'All Programs',
  Label_AllSamples: 'All Samples',
  Label_Year: 'Year',
  Label_Quarter: 'Quarter',
  Label_MissingEnrollments: 'Missing from Enrollments (Found in Import File):  Go <0>here</0> to fix',
  Label_ExtraEnrollments: 'Extra in Enrollments (Missing from Import File) : Go <0>here</0> to fix',
  Label_EditCycleEnrollment: 'Edit Enrollment For Cycle {{cycleName}}',
  Label_ExtraParameters: 'Extra in MPU (Missing from Import File): Go <0>here</0> to fix',
  Label_ExtraNAParameters: 'Extra NA Parameters',
  Label_MissingParameters: 'Missing from TPU (Found in Import File): Go <0>here</0> to fix',
  Label_RetriedBy: 'Retried on {{retryOn}} by {{retryBy}}',
  Label_All: 'All',
  Label_Approved: 'Approved',
  Label_NotApproved: 'Not Approved',
  Label_ActiveLocations: 'Active Locations',
  Label_InactiveLocations: 'InActive Locations',
  Label_ItemsToTest: 'Items to Test',
  Label_PendingApprovalNAItem: 'Pending Approval NA Item',
  Label_FrequencyPerYear: 'Frequency/Year',
  Label_TotalNAItem: 'Total NA Item',
  Label_Stretch: 'Stretch',
  Label_Compact: 'Compact',
  Label_ShowCreatedCARs: 'Show Created CARs',
  Label_AutoSave: 'Auto Save',
  Label_MethodBase: 'Method Base',
  Label_ParameterBase: 'Parameter Base',
  Label_CalculationStrategy: 'Calculation Strategy',
  Label_GroupName: 'Group Name',
  Label_CreatedBy: 'Created By',
  Label_CreatedOn: 'Created On',
  Label_ZScore: 'Z Score',
  Label_TypicalTAT: 'Typical TAT',
  Label_Cost_Per_Test: 'Cost Per Test',
  Label_Daily_Sample_Capacity: 'Daily Sample Capacity',
  Label_Level: 'Level',
  Label_Note: 'Note',
  Label_EditMethod: 'Edit Method',
  Label_DuplicateParamaterAddConfirmation: 'Selected Parameter(s) already added to the program, do you wish to add them as duplicate?',
  Label_Programs: 'Programs',

  Message_NoComments: 'No Comments',
  Message_NoItems: 'No items!',
  Message_RecalculationNeededForStats: 'Recalculation needed for stats',
  Message_RecalculationNeededForScore: 'Recalculation needed for scores',
  Confirm_Message_UnsavedProgram: 'Do you wish to Close the window without saving the Progam?',
  Confirm_Message_Delete: 'Are you sure you want to delete this method ?',

  Nav_Menu_Program: 'Programs',
  Nav_Menu_ActionCenter: 'Action Center',
  Nav_Menu_Actions: 'Actions',
  Nav_Menu_Sent_Emails: 'Sent Emails',
  Nav_Menu_MyActions: 'My Actions',
  Nav_Menu_Reports: 'Reports',
  Nav_Menu_LabSummaryReport: 'Location Report',
  Nav_Menu_OutstandingCARs: 'Outstanding CARs',
  Nav_Menu_Trends: 'Trends',
  Nav_Menu_TrendingGraphs: 'Trending Graphs',
  Nav_Menu_ZCarPercentageReport: 'Z CAR % Report',
  Nav_Menu_Capability: 'Capability',
  Nav_Menu_Performance: 'Performance',

  Placeholder_Selectanoption: 'Select an option',
  PlaceHolder_Comments: 'Comments',
  PlaceHolder_TypeClearToReset: 'Type clear to reset',

  Tab_Title_AppName: 'CTD CPMS',
  Tab_Profile: 'Profile',
  Tab_Announcement: 'Announcement',
  Tab_Notification: 'Notification',

  Table_NoUserfound: 'No User Found!',

  Text_AddProgram: 'Add Program',
  Text_EditContacts: 'Edit Contact',
  Text_EditAsset: 'Edit Asset',
  Text_AddAsset: 'Add Asset',
  Text_AddContacts: 'Add Contact',
  Text_ResultReview: 'Result Review',
  Text_AddCycle: 'Add Cycle',
  Text_EditCycle: 'Edit Cycle',
  Text_LocationID: 'Location ID',
  Text_LocationName: 'Location Name',
  Text_LocationCode: 'Location Code',
  Text_LocationType: 'Location Type',
  Text_BusinessLine: 'Business Line',
  Text_Company: 'Company',
  Text_AddLocation: 'Add Location',
  Text_EditLocation: 'Edit Location',
  Text_EditRole: 'Edit Role',
  Text_AddRole: 'Add Role',
  Text_AddAnnouncement: 'Add Announcement',
  Text_AddMyAnnouncement: 'Add My Announcement',
  Text_EditAnnouncement: 'Edit Announcement',
  Text_AddGroup: 'Add Group',
  Text_EditGroup: 'Edit Group',
  Text_EditSubjectMatterExpertise: 'Edit Subject Matter Expertise',
  Text_ViewAnnouncement: 'View Announcement',
  Text_MyViewAnnouncement: 'View Announcement',
  Text_True: 'True',
  Text_False: 'False',
  Text_CARPreview: 'CAR Preview',
  Text_MDUpdateConfirmation: 'Are you sure you want to update Master Data?',
  Text_UpdateMD: 'Update Master Data',
  Text_OnlyImportDataFile: 'Only Data Import files to be uploaded here',
  Text_InActivation_Popup: 'InActivation Of Asset',
  Text_ReasonOfInActivation: 'Reason of InActivation',
  Text_InactiveAsset: 'Inactive Asset',

  Title_Showing_X_Records: 'Showing {{length}} record(s)',
  Title_NewList: 'New List',
  Title_NewMethodEquivalence: 'New Method Equivalence',
  Title_CompanySummaryReport: 'Company Summary Report',
  Title_DataExport: 'Data Export',
  Title_ReportGrading: 'Report Grading',
  Title_ExportImportDataFile: 'Export Import Data File',
  Title_NewMethod: 'New Method',
  Title_NewSubjectMatterExpertise: 'New Subject Matter Expertise',
  Title_HistoricZScore: 'Historic Z Score',
  Title_NewAsset: 'New Asset',
  Title_ReadAndUnderstandView: 'Read & Understand View',

  Toast_CreatedSuccessfully: 'Created Successfully',
  Toast_CreateFailed: 'Create Failed',
  Toast_UpdatedSuccessfully: 'Updated Successfully',
  Toast_UpdateFailed: 'Update Failed',
  Toast_FilterDisplayUpdatedSuccessfully: 'Filter Display updated successfully!',
  Toast_FilterDisplayUpdateFailed: 'Filter Display update failed!',
  Toast_UnableToSaveFilter: 'Unable to save filter preset!',
  Toast_UnableToSave: 'Unable To Save',
  Toast_SavedSuccessfully: 'Save sucessfully!',
  Toast_ActionStatusUpdatedSuccessfully: 'Action status updated successfully!',
  Toast_FilesUploadedSuccessfully: 'File(s) uploaded successfully',
  Toast_FileRemovedSuccessfully: 'File removed successfully',
  Toast_FileDownloadedSuccessfully: 'File downloaded successfully!',
  Toast_FileDeletedSuccessfully: 'File(s) deleted successfully',
  Toast_MethodAndParameterAlreadyExists: 'Selected Method and Parameter already exists in Program',
  Toast_AcknowledgedSuccessfully: 'Acknowledged Successfully',
  Toast_ValidatedSuccessfully: 'Validated Successfully',
  Toast_CommentRequiredForFCar: 'Comment is required when FCar is true',
  Toast_ULabMustBeDecimal: 'U Lab must be decimal number',
  Toast_RecalculatedSuccessfully: 'Recalculated Successfully',
  Toast_RecalculatingStatsScores: 'Please wait while system is recalculating stats/scores',
  Toast_FinalizeSuccessfully: 'Finalize Successful, CAR creation in progress, once created, it can be seen in Audit',
  Toast_RefinalizeSuccessfully: 'Refinalize Successful, CARs creation in progress and can be checked in Audit UI',
  Toast_UnitTypeNotSelected: 'Unit Type not selected',
  Toast_RecordAlreadyExists: 'Record - {{key}} already exists',
  Toast_CountriesAddedSuccessfully: 'Country/Countries added successfully',
  Toast_CountryCodeAndNameRequired: "'Country Name' and 'Country Code' is mandatory to fill!",
  Toast_MaxCharLengthErrorMessage: '{{fieldName}} must be less than {{n}} characters',
  Toast_TestMethodDeletedSuccessfully: 'TestMethod deleted successfully',

  Tooltip_EditCycle: 'Edit Cycle',
  Tooltip_ViewCycle: 'View Cycle',
  Tooltip_Groups: 'CPMS | Groups',
  Tooltip_CycleSearch: 'CPMS | Cycles',
  Tooltip_AuditSearch: 'CPMS | Audit Search',
  Tooltip_ActionSearch: 'CPMS | Action Search',
  Tooltip_List: 'CPMS | List',
  Tooltip_OrganizationSearch: 'CPMS | Organizations',
  Tooltip_Region: 'CPMS | Regions',
  Tooltip_Role: 'CPMS | Roles',
  Tooltip_SubjectMatterExpertise: 'CPMS | Subject Matter Expertise',
  Tooltip_User: 'CPMS | Users',
  Tooltip_Announcements: 'CPMS | Announcements',
  Tooltip_MyAnnouncements: 'CPMS | My Announcements',
  Tooltip_ResultReview: 'Result Review',
  Tooltip_AddProgram: 'Add Program',
  Tooltip_AddCycle: 'Add Cycle',
  Tooltip_EditSubjectMatterExpertise: 'Edit Subject Matter Expertise',
  Tooltip_AddAnnouncement: 'Add Announcement',
  Tooltip_EditAnnouncement: 'Edit Announcement',
  Tooltip_MyEditAnnouncement: 'Edit My Announcement',
  Tooltip_AccreditationBodies: 'Accreditation Bodies',
  Tooltip_Download: 'Download',
  Tooltip_Default: 'Default',
  Tooltip_AddLocation: 'CPMS | Add Location',
  Tooltip_EditLocation: 'CPMS | Edit Location',
  Tooltip_Methods: 'CPMS | Methods',
  Tooltip_Products: 'CPMS | Products',
  Tooltip_Export_To_CSV: 'Export to CSV',
  Tooltip__NewEnrollment: 'New Enrollment',
  Tooltip_Locations: 'CPMS | Locations',
  Tooltip_Countries: 'CPMS | Countries',
  Tooltip_Units: 'CPMS | Units',
  Tooltip_IgnoreList: 'CPMS | Ignore List',
  Tooltip_Contacts: 'CPMS | Contacts',
  Tooltip_SentEmails: 'CPMS | Sent Emails',
  Tooltip_AddAsset: 'CPMS | Add Asset',
  Tooltip_EditAsset: 'CPMS | Edit Asset',
  Tooltip_EditContact: 'CPMS | Edit Contact',
  Tooltip_AddGroup: 'CPMS | Add Group',
  Tooltip_EditGroup: 'CPMS | Edit Group',
  Tooltip_AnnouncementView: 'CPMS | Announcement View',
  Tooltip_ReviewResults: 'CPMS | Review Results',
  Tooltip_CARPreview: 'CPMS | CAR Preview',
  Tooltip_Export_To_Excel: 'Export to Excel',
  Tooltip_Favorite: 'Favorite',
  Tooltip_Unfavorite: 'Unfavorite',
  Tooltip_EditLayout: 'Edit Layout',
  Tooltip_ReadAndUnderstand: 'Read And Understand',
  Tooltip_MethodEquivalence: 'Method Equivalence',
  Tooltip_CompanySummaryReport: 'CPMS | Company Summary Report',
  Tooltip_DataExport: 'CPMS | Data Export',
  Tooltip_ReportGrading: 'CPMS | Report Grading',
  Tooltip_LabSummaryReport: 'CPMS | Location Report',
  Tooltip_AndersonDarling: 'Anderson Darling',
};

export default enTranslation;
