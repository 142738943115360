import { lazy } from 'react';
import MainLayout from './components/layouts/MainLayout';
import Loadable from './components/ui/Loadable';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/Guards/AuthGuard/AuthGuard';
import PermissionGuard, { PermissionCodes, PermissionRights as Rights, } from './components/Guards/PermissionGuard/PermissionGuard';

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('./pages/auth/components/ForgotPassword/ForgotPassword')));
const ClientPassword = Loadable(lazy(() => import('./pages/User/components/ClientPassword')));

// Experiment Feautures
const ExperimentFeature = Loadable(
  lazy(() => import('./pages/ExperimentFeature/ExperimentFeature'))
);

// cycle search
const CycleSearch = Loadable(
  lazy(() => import('./pages/CycleSearch/CycleSearch'))
);

const CycleSearchAddForm = Loadable(
  lazy(() => import(
    './pages/CycleSearch/components/CycleSearchAddForm/CycleSearchAddForm'
  ))
);
const CycleSearchEditForm = Loadable(
  lazy(() => import(
    './pages/CycleSearch/components/CycleSearchEditForm/CycleSearchEditForm'
  ))
);

const CycleResultEntry = Loadable(
  lazy(() => import('./pages/CycleSearch/components/ResultEntry/ResultEntry'))
);

const TrendingGraphSearch = Loadable(
  lazy(() => import('./pages/TrendingGraphs/TrendingGraphSearch'))
);

const StatsEntry = Loadable(
  lazy(() => import(
    './pages/CycleSearch/components/StatsEntry/StatsEntry'
  ))
);

const CycleReviewResult = Loadable(
  lazy(() => import('./pages/CycleSearch/components/ReviewResult/ReviewResult'))
);

const CycleCARPreview = Loadable(lazy(() => import('./pages/CycleSearch/components/CARPreview/CARPreview')));
const SampleRefinalize = Loadable(lazy(() => import('./pages/CycleSearch/components/SampleRefinalize/SampleRefinalize')));

const ActionsSearch = Loadable(
  lazy(() => import('./pages/ActionSearch/ActionSearch'))
);
const EmailSearch = Loadable(
  lazy(() => import('./pages/Emails/EmailSearch'))
);
const MyActions = Loadable(
  lazy(() => import('./pages/MyActions/MyActions'))
);

// NA List
const NAListSearch = Loadable(
  lazy(() => import('./pages/NAList/NAListSearch'))
);

const NAListSearchResult = Loadable(
  lazy(() => import('./pages/NAListSearchResult/NAListSearchResult'))
);

const NAListSearchLocationResult = Loadable(
  lazy(() => import('./pages/NAListSearchLocationResult/NAListSearchLocationResult'))
);

// List
const ListPage = Loadable(
  lazy(() => import('./pages/List/ListSearch'))
);

const LabSummaryReportSearch = Loadable(
  lazy(() => import('./pages/LabSummaryReport/LabSummaryReportSearch'))
);
const OutstandingCARs = Loadable(
  lazy(() => import('./pages/OutstandingCARs/OutstandingCARs'))
);
const EditList = Loadable(
  lazy(() => import('./pages/List/ListDetails'))
);

// Enrollment
const EnrollmentSearch = Loadable(
  lazy(() => import('./pages/Enrollment/EnrollmentSearch'))
);

const EnrollmentAddForm = Loadable(
  lazy(() => import('./pages/Enrollment/components/EnrollmentAddForm/EnrollmentAddForm'))
);

const EnrollmentSearchAction = Loadable(
  lazy(() => import('./pages/Enrollment Search Action/EnrollmentSearchAction'))
);

const EnrollmentSearchByProgram = Loadable(
  lazy(() => import('./pages/Enrollment Search By Location/EnrollmentSearchByLocation'))
);

// cycle search
const ProgramSearch = Loadable(
  lazy(() => import('./pages/Program/ProgramSearch'))
);

// Products
const ProductSearch = Loadable(lazy(() => import('./pages/Products/Products')));

const CountriesSearch = Loadable(lazy(() => import('./pages/Countries/Countries')));

// Error pages
const AuthorizationError = Loadable(
  lazy(() => import('./pages/error/AuthorizationError'))
);
const MethodSearch = Loadable(lazy(() => import('./pages/MethodSearch/MethodSearch')));

const MethodAddEditForm = Loadable(
  lazy(() => import('./pages/MethodSearch/components/MethodAddEditForm/MethodAddEditForm'))
);

const OrganizationSearch = Loadable(lazy(() => import('./pages/Organization/OrganizationSearch')));

const ProgramDetails = Loadable(
  lazy(() => import('./pages/Program/ProgramDetails'))
);

const AuditSearch = Loadable(
  lazy(() => import('./pages/AuditSearch/AuditSearch'))
);

const LocationSearch = Loadable(
  lazy(() => import('./pages/Location/LocationSearch'))
);
const LocationSearchAddEditForm = Loadable(
  lazy(() => import('./pages/Location/components/LocationSearchAddEditForm/LocationSearchAddEditForm'))
);

const LocationView = Loadable(
  lazy(() => import('./pages/Location/components/LocationView/LocationView'))
);

const UnitSearch = Loadable(
  lazy(() => import('./pages/Unit/UnitSearch'))
);
const AssetSearch = Loadable(
  lazy(() => import('./pages/Asset/AssetSearch'))
);

const IgnoreListSearch = Loadable(
  lazy(() => import('./pages/IgnoreList/IgnoreListSearch'))
);

const RegionSearch = Loadable(
  lazy(() => import('./pages/Region/RegionSearch'))
);

const RoleSearch = Loadable(
  lazy(() => import('./pages/Role/RoleSearch'))
);
const RoleSearchAddEdit = Loadable(
  lazy(() => import('./pages/Role/components/RoleSearchAddEditForm/RoleSearchAddEditForm'))
);

const UserSearch = Loadable(lazy(() => import('./pages/User/UserSearch')));
const UserAddEditForm = Loadable(lazy(() => import('./pages/User/components/UserAddEditForm/UserAddEditForm')));

const AccountSetting = Loadable(
  lazy(() => import('./pages/AccountSetting/AccountSetting'))
);

// Subject Matter Expertise
const SubjectMatterExpertisePage = Loadable(
  lazy(() => import('./pages/SubjectMatterExpertise/SubjectMatterExpertise'))
);
const SubjectMatterExpertiseEdit = Loadable(
  lazy(() => import('./pages/SubjectMatterExpertise/SubjectMatterExpertiseEdit'))
);

// Contact Search
const ContactsSearch = Loadable(
  lazy(() => import('./pages/Contacts/ContactsSearch'))
);

const ContactsSearchAddEditForm = Loadable(
  lazy(() => import('./pages/Contacts/components/ContactsSearchAddEditForm/ContactsSearchAddEditForm'))
);

const AssetSearchAddEditForm = Loadable(
  lazy(() => import('./pages/Asset/components/AssetAddEdit/AssetAddEdit'))
);

// MyAnnouncements Search
const AnnouncementsPage = Loadable(
  lazy(() => import('./pages/Announcements/Announcements'))
);

const AnnouncementAddEditForm = Loadable(
  lazy(() => import('./pages/Announcements/components/AnnouncementAddEditForm/AnnouncementAddEditForm'))
);

const AnnouncementView = Loadable(
  lazy(() => import('./pages/Announcements/components/AnnouncementView/AnnouncementView'))
);

// Groups Search
const GroupsPage = Loadable(
  lazy(() => import('./pages/GroupsSearch/GroupsSearch'))
);

const GroupAddEditForm = Loadable(
  lazy(() => import('./pages/GroupsSearch/components/GroupAddEditForm/GroupAddEditForm'))
);

// AccreditationBodies Search
const AccreditationBodiesPage = Loadable(
  lazy(() => import('./pages/AccreditationBodies/AccreditationBodies'))
);

const AccreditationBodiesEditPage = Loadable(
  lazy(() => import('./pages/AccreditationBodies/AccreditationBodiesAddEdit'))
);

const MethodEquivalenceSearch = Loadable(
  lazy(() => import('./pages/MethodEquivalence/MethodEquivalenceSearch'))
);

const MethodEquivalenceEdit = Loadable(
  lazy(() => import('./pages/MethodEquivalence/components/MethodEquivalenceEdit/MethodEquivalenceEdit'))
);

const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));
const Labs = Loadable(lazy(() => import('./pages/labs/Labs')));

// MyAnnouncements Search
const MyAnnouncementsPage = Loadable(lazy(() => import('./pages/MyAnnouncements/MyAnnouncements')));

const ReportGrading = Loadable(
  lazy(() => import('./pages/ReportGrading/ReportGrading'))
);
const CompanySummaryReport = Loadable(
  lazy(() => import('./pages/ComapnySummaryReport/CompanySummaryReport'))
);

const DataExport = Loadable(
  lazy(() => import('./pages/DataExport/DataExport'))
);

const ZCARPercentageReport = Loadable(
  lazy(() => import('./pages/ZCARReport/ZCarPercentageReport'))
);

const LabSummaryReportView = Loadable(
  lazy(() => import('./pages/LabSummaryReport/LabSummaryReportView/LabSummaryReportView'))
);

const CompanySummaryReportView = Loadable(
  lazy(() => import('./pages/ComapnySummaryReport/CompanySummaryReportView/CompanySummaryReportView'))
);

const LegalNotice = Loadable(lazy(() => import('./pages/Privacy/LegalNotice')));
const CookiesPolicy = Loadable(
  lazy(() => import('./pages/Privacy/CookiesPolicy'))
);
const TermsOfUse = Loadable(lazy(() => import('./pages/Privacy/TermsOfUse')));
const InformationNoticeToUser = Loadable(
  lazy(() => import('./pages/Privacy/InformationNoticeToUser'))
);

const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));

const routes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
    ],
  },
  {
    path: 'labs',
    // element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Labs />,
      },
    ],
  },
  {
    path: 'experiment',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <ExperimentFeature />,
      },
    ],
  },
  {
    path: 'list',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.List} right={Rights.Search}>
            <ListPage />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.List} right={Rights.Edit}>
            <EditList />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'reports',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'locationReport',
        element: (
          <PermissionGuard permission={PermissionCodes.LabSummaryReport} right={Rights.Search}>
            <LabSummaryReportSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'locationReport/:sampleID?/:locationId?/report',
        element: (
          <PermissionGuard permission={PermissionCodes.LabSummaryReport} right={Rights.Search}>
            <LabSummaryReportView />
          </PermissionGuard>
        ),
      },
      {
        path: 'companySummary',
        element: (
          <PermissionGuard permission={PermissionCodes.CompanySummaryReport} right={Rights.Search}>
            <CompanySummaryReport />
          </PermissionGuard>
        ),
      },
      {
        path: 'companySummary/:sampleID?/report',
        element: (
          <PermissionGuard permission={PermissionCodes.LabSummaryReport} right={Rights.Search}>
            <CompanySummaryReportView />
          </PermissionGuard>
        ),
      },
      {
        path: 'zCarPercentageReport',
        element: (
          <PermissionGuard permission={PermissionCodes.ZCarPercentageReport} right={Rights.Search}>
            <ZCARPercentageReport />
          </PermissionGuard>
        ),
      },
      {
        path: 'reportGrading',
        element: (
          <PermissionGuard permission={PermissionCodes.ReportGrading} right={Rights.Search}>
            <ReportGrading />
          </PermissionGuard>
        ),
      },
      {
        path: 'outstandingCARs',
        element: (
          <PermissionGuard permission={PermissionCodes.OutstandingCARsReport} right={Rights.Search}>
            <OutstandingCARs />
          </PermissionGuard>
        )
      },
      {
        path: 'trends',
        element: (
          <PermissionGuard permission={PermissionCodes.TrendingGraphReport} right={Rights.Search}>
            <TrendingGraphSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'dataExport',
        element: (
          <PermissionGuard permission={PermissionCodes.DataExport} right={Rights.Search}>
            <DataExport />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'cycles',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Cycle} right={Rights.Search}>
            <CycleSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Cycle} right={Rights.Add}>
            <CycleSearchAddForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Cycle} right={Rights.Search}>
            <CycleSearchEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':cycleID/ResultEntry/:sampleIds?/:text?',
        element: (
          <PermissionGuard permission={PermissionCodes.ResultEntry} right={Rights.Search}>
            <CycleResultEntry />
          </PermissionGuard>
        )
      },
      {
        path: ':cycleID/StatsEntry/:sampleIds?/:text?',
        element: (
          <PermissionGuard permission={PermissionCodes.StatsEntry} right={Rights.Search}>
            <StatsEntry />
          </PermissionGuard>
        ),
      },
      {
        path: ':cycleID/Review',
        element: (
          <PermissionGuard permission={PermissionCodes.SampleReview} right={Rights.Search}>
            <CycleReviewResult />
          </PermissionGuard>
        )
      },
      {
        path: ':cycleID/Review/:selectedSampleId',
        element: (
          <PermissionGuard permission={PermissionCodes.SampleReview} right={Rights.Search}>
            <CycleReviewResult />
          </PermissionGuard>
        )
      },
      {
        path: ':cycleID/finalize/:sampleId?/:sampleName?',
        element: (
          <PermissionGuard permission={PermissionCodes.Cycle} right={Rights.Search}>
            <CycleCARPreview />
          </PermissionGuard>
        )
      },
      {
        path: ':cycleID/carpreview/:sampleId?/:sampleName?',
        element: (
          <PermissionGuard permission={PermissionCodes.Cycle} right={Rights.Search}>
            <CycleCARPreview readOnly />
          </PermissionGuard>
        )
      },
      {
        path: ':cycleID/refinalize/:sampleId?/:sampleName?',
        element: (
          <PermissionGuard permission={PermissionCodes.Cycle} right={Rights.Search}>
            <SampleRefinalize />
          </PermissionGuard>
        )
      },
    ],
  },
  {
    path: 'naList',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.NAList} right={Rights.Search}>
            <NAListSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'audits',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Audit} right={Rights.Search}>
            <AuditSearch />
          </PermissionGuard>
        )
      },
    ],
  },
  {
    path: 'naListSearchResult',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <NAListSearchResult />,
      },
    ],
  },
  {
    path: 'naListSearchLocationResult',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <NAListSearchLocationResult />,
      },
    ],
  },
  {
    path: 'actions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Action} right={Rights.Search}>
            <ActionsSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'sentEmails',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Email} right={Rights.Search}>
            <EmailSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'MyActions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MyActions} right={Rights.Search}>
            <MyActions />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'enrollments',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Enrollment} right={Rights.Search}>
            <EnrollmentSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add/p/:programId/:text',
        element: (
          <PermissionGuard permission={PermissionCodes.Enrollment} right={Rights.Add}>
            <EnrollmentAddForm />
          </PermissionGuard>
        ),
      },
      {
        path: 'add/l/:locationId/:text',
        element: (
          <PermissionGuard permission={PermissionCodes.Enrollment} right={Rights.Add}>
            <EnrollmentAddForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'enrollmentSearchAction',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <EnrollmentSearchAction />,
      },
    ],
  },
  {
    path: 'enrollmentSearchByProgram',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <EnrollmentSearchByProgram />,
      },
    ],
  },
  {
    path: 'program',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Program} right={Rights.Search}>
            <ProgramSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Program} right={Rights.Add}>
            <ProgramDetails />
          </PermissionGuard>
        ),
      },
      // Order Program edit route is important as it will allow edit if user have edit permission,
      // otherwise will match the route where it is readonly
      {
        path: ':programId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Program} right={Rights.Search}>
            <ProgramDetails />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'organization',
    element: <AuthGuard><MainLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Organization} right={Rights.Search}>
            <OrganizationSearch />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'products',
    element: <AuthGuard><MainLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Product} right={Rights.Search}>
            <ProductSearch />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'countries',
    element: <AuthGuard><MainLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Country} right={Rights.Search}>
            <CountriesSearch />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'methods',
    element: <AuthGuard><MainLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.TestMethod} right={Rights.Search}>
            <MethodSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.TestMethod} right={Rights.Add}>
            <MethodAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.TestMethod} right={Rights.Edit}>
            <MethodAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'locations',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Search}>
            <LocationSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Add}>
            <LocationSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Edit}>
            <LocationSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/view',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Search}>
            <LocationView />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'assets',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Asset} right={Rights.Search}>
            <AssetSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'asset',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Asset} right={Rights.Edit}>
            <AssetSearchAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'units',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Unit} right={Rights.Search}>
            <UnitSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'ignoreList',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.IgnoreList} right={Rights.Search}>
            <IgnoreListSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'regions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Region} right={Rights.Search}>
            <RegionSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'roles',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Search}>
            <RoleSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Add}>
            <RoleSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Edit}>
            <RoleSearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: '/users',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Search}>
            <UserSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Add}>
            <UserAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':userId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Edit}>
            <UserAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'account',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AccountSetting />,
      },
    ],
  },
  {
    path: 'contacts',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Contact} right={Rights.Search}>
            <ContactsSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Contact} right={Rights.Add}>
            <ContactsSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Contact} right={Rights.Edit}>
            <ContactsSearchAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'sme',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.SubjectMatterExpertise} right={Rights.Search}>
            <SubjectMatterExpertisePage />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.SubjectMatterExpertise} right={Rights.Edit}>
            <SubjectMatterExpertiseEdit />
          </PermissionGuard>
        ),
      },
    ]
  },
  {
    path: 'announcements',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Announcement} right={Rights.Search}>
            <AnnouncementsPage />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Announcement} right={Rights.Add}>
            <AnnouncementAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Announcement} right={Rights.Edit}>
            <AnnouncementAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/view',
        element: (
          <PermissionGuard permission={PermissionCodes.MyAnnouncement} right={Rights.Search}>
            <AnnouncementView />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'groups',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Group} right={Rights.Search}>
            <GroupsPage />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Group} right={Rights.Add}>
            <GroupAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Group} right={Rights.Edit}>
            <GroupAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'accreditationBodies',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.AccreditationBody} right={Rights.Search}>
            <AccreditationBodiesPage />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.AccreditationBody} right={Rights.Add}>
            <AccreditationBodiesEditPage />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.AccreditationBody} right={Rights.Edit}>
            <AccreditationBodiesEditPage />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'methodEquivalence',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MethodEquivalence} right={Rights.Search}>
            <MethodEquivalenceSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.MethodEquivalence} right={Rights.Edit}>
            <MethodEquivalenceEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'myannouncements',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MyAnnouncement} right={Rights.Search}>
            <MyAnnouncementsPage />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'privacy',
    children: [
      {
        path: '',
        element: <LegalNotice />,
      },
      {
        path: '/privacy/legalnotice',
        element: <LegalNotice />,
      },
      {
        path: '/privacy/CookiesPolicy',
        element: <CookiesPolicy />,
      },
      {
        path: '/privacy/TermsOfUse',
        element: <TermsOfUse />,
      },
      {
        path: '/privacy/InformationNoticeToUser',
        element: <InformationNoticeToUser />,
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '',
        exact: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'Auth/forgot',
        element: <ForgotPassword />,
      },
      {
        path: '401',
        element: <AuthorizationError />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: 'changepassword/:tokenId',
        element: <ClientPassword />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
